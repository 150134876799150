/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-new */
import UIkit from 'uikit';
import SimpleBar from 'simplebar';

// for (const scrollArear of document.getElementsByClassName('modal-sroller-el')) {
//   new SimpleBar(scrollArear, {
//       autoHide: false
//   }); 
// }
let barMenu;
if ( $('#modal-sroller-el').length ) {
  barMenu = new SimpleBar(document.getElementById('modal-sroller-el'), {
    autoHide: false
  });
}

$(document).on("click",".js-show-mob-menu .js-burger",function() {
  UIkit.modal($('#modal-mob-menu')).show();
  if ( $('#modal-mob-menu').is('visible') ) {
    UIkit.modal($('#modal-mob-menu')).hide();
  }
  // barMenu.recalculate();
});
$(document).on("click",".js-show-mob-menu .js-burger-close, .nav-list .nav-list__link",function() {
  UIkit.modal($('#modal-mob-menu')).hide();
  if ( $('#modal-mob-menu').is('visible') ) {
    UIkit.modal($('#modal-mob-menu')).hide();
  }
});

$('.nav-list .nav-list__link').hover(function () {
  UIkit.modal($('#modal-mob-menu')).hide();
  $('.js-show-mob-menu, .header-main').removeClass('active');
  }, function () {
    
  }
);

UIkit.util.on('#modal-mob-menu', 'hidden', function () {

    $('.js-show-mob-menu, .header-main').removeClass('active');


});
UIkit.util.on('#modal-mob-menu', 'shown', function () {

    $('.js-show-mob-menu, .header-main').addClass('active');

 

  

});