/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable func-names */
import UIkit from 'uikit';




$(document).ready(function () {

  let gridCatalog = UIkit.grid('.js-catalog-grid', {

  });  


  $(document).on("click", ".js-catalog-toggle", function() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 20);
    
    $('.js-catalog-toggle').removeClass('active');
    $(this).addClass('active');
   
  });
  
  $('.js-catalog-toggle.grid3').click(function (e) { 
    e.preventDefault();
    $('.js-product-grid').removeClass('uk-width-1-1, uk-width-1-2');
    $('.js-product-grid').addClass('uk-width-1-1');
    $('.js-product-grid').find('.product__item').removeClass('product-grid3, product-grid2');
    $('.js-product-grid').find('.product__item').addClass('product-grid3');

  });

  $('.js-catalog-toggle.grid2').click(function (e) { 
    e.preventDefault();
    $('.js-product-grid').removeClass('uk-width-1-1');
    $('.js-product-grid').addClass('uk-width-1-2');
    $('.js-product-grid').find('.product__item').removeClass('product-grid3');
    $('.js-product-grid').find('.product__item').addClass('product-grid2');
    
    
    
  });

  $('.js-catalog-toggle.grid').click(function (e) { 
    e.preventDefault();
    $('.js-product-grid').removeClass('uk-width-1-2');
    $('.js-product-grid').addClass('uk-width-1-1');
    $('.js-product-grid').find('.product__item').removeClass('product-grid3');
    $('.js-product-grid').find('.product__item').removeClass('product-grid2');
    

  });

});