/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable func-names */
/* eslint-disable no-undef */
let getHeighWhite = (elem, elemHeight, elemBig) => {

  
  
}
setTimeout(() => {
  $.each($('.js-to-height'), function (indexInArray, valueOfElement) { 

    let elemHeight = $(this).closest('.js-big-height').find('.js-biginfo-height');
    let elemBig = $(this).closest('.js-big-height');
    
    let heightSmall = elemHeight.innerHeight()/2;
    let heightBig = elemBig.innerHeight();
    let sumH = heightBig - heightSmall;
    // console.log(heightSmall);

    $(this).css('height', `${sumH}px`);
    $(this).css('opacity', '1');
  });
}, 100);



window.onresize = function(event) {
  getHeighWhite($('.js-to-height'),$('.js-biginfo-height'),$('.js-big-height'))

  $.each($('.js-to-height'), function (indexInArray, valueOfElement) { 

    let elemHeight = $(this).closest('.js-big-height').find('.js-biginfo-height');
    let elemBig = $(this).closest('.js-big-height');
    
    let heightSmall = elemHeight.innerHeight()/2;
    let heightBig = elemBig.innerHeight();
    let sumH = heightBig - heightSmall;
    // console.log(heightSmall);

    $(this).css('height', `${sumH}px`);
    $(this).css('opacity', '1');
  });

};