/* eslint-disable no-restricted-syntax */
/* eslint-disable no-new */
import SimpleBar from 'simplebar';


// scrollde block
let elemScroll;
for (const scrollArea of document.getElementsByClassName('sroller-el')) {
  elemScroll = new SimpleBar(scrollArea, {
      autoHide: false
  });
}



$('.categoies-drop .drop-link').hover(function () {
  elemScroll.recalculate();
    
  }, function () {

  }
);

// scrollde block
for (const scrollArea of document.getElementsByClassName('sroller-el-h')) {
  new SimpleBar(scrollArea, {
      autoHide: false,
      forceVisible: true
  });
}

// scrollde block
for (const scrollArea of document.getElementsByClassName('breadcrumb')) {
  new SimpleBar(scrollArea, {
      autoHide: false,
      // forceVisible: true
  });
}

