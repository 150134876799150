/* eslint-disable no-unused-vars */
import UIkit from 'uikit';


const getClassTooltip = () => {
  return this.element.attr('tooltip-cls');
}
UIkit.tooltip(('.js-tooltip'), {
  title: '',
  offset: 0
});

// UIkit.util.on(document, 'beforeshow', '.uk-tooltip', 
// function(e) {
//   e.cls = "asdsad";
//   console.log( 123123);
// }
// )
// UIkit.tooltip('.js-tooltip').show();