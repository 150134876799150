/* eslint-disable dollar-sign/dollar-sign */
/* eslint-disable spaced-comment */
/* eslint-disable prefer-template */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable vars-on-top */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable radix */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-var */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable func-names */
// eslint-disable-next-line no-unused-vars
import ionRangeSlider from 'ion-rangeslider';



$(document).ready(function () {

  const TYPE_CHECKBOX = 1;
  const TYPE_RANGE = 2;
  const TYPE_INPUT = 3;
  const TYPE_SEARCH = 4;
  const START_PRICE = $('#start_price').val();
  const END_PRICE = $('#end_price').val();;
  const DONTADD = 2;

  let catalogUrl = window.location.pathname;
  let html;
  let cat_cpu;

  
  function appendFilters(item, index, type=TYPE_CHECKBOX) {
    var myclass='';
    var range_start = $('.js-input-from').val();

    var range_end = $('.js-input-to').val();
    if(type == TYPE_RANGE){


        if(range_start==START_PRICE && range_end==END_PRICE){
            myclass = 'hide_range';


        }


    }

        html = " <a href=\"\" data-type='" + type + "' data-delfilter='" + item + "' class=' " + item + " " + myclass + " filter__selected'>" + index + "\n" +
            "                                                <svg class=\"icon icon-delete-filter\">\n" +
            "                                                    <use xlink:href=\"img/sprite.svg#icon-delete-filter\"></use>\n" +
            "                                                </svg>\n" +
            "                                            </a>"

        $(".filter__choise").append(html);


  /*  if ( $( ".hide_range" ).length ) {
        $(".filter__clear").hide();
    }*/


    $(".hide_range").remove();



    if($('.filter__choise').text()=='' || $('.filter__choise').text()=='  '){
        $(".filter__clear").hide();
        $(".m_filter__title").hide();
    }
    else{
       // console.log($('.filter__choise').text());
        $(".filter__clear").show();
        $(".m_filter__title").show();
    }
}

  function changeFilters(page = 1, adding=1){

    console.log('asdasd');
      $('.you-selected').show();
      let ajaxData = getFilters(page, adding);
      console.log(ajaxData);
      $.ajax({
          url: "/goods/changeFilter",
          method: "POST",
          data: ajaxData,
          dataType: "text",
          success (data) {
              if (data != '') {
                  $('.show_goods').html(data);
                  var good_count = $('.count_good').val();
                  $('.title-count').html(good_count);
              } else {
                  $('.show_goods').html($('#textfield_NoData').val());
              }

          },
          complete(xhr){
              $('#loaderForm').hide();

              window.history.pushState('filter', 'Catalog', `${catalogUrl}?${serialize(ajaxData)}`);
              $('.export_catalog_pdf').attr("href", `/goods/goods-price?format=pdf&type=all_filter&${serialize(ajaxData)}`);
              $('.export_catalog_xls').attr("href", `/goods/goods-price?format=xls&type=all_filter&${serialize(ajaxData)}`);
              $('#load_data_table').show();


          },
          statusCode: {
            404() {
              console.log('There was a problem with the server.  Try again soon!');
            }
          }
      });
  }

  function getFilters(page = 1, adding=1) {
      $('#loaderForm').show();
      $(".filter__choise").html('');
      var brand_filters = '';

      $('input:checkbox.brand_check:checked').each(function () {
          brand_filters += `${$(this).val()  },`;
        //  params_selected[$(this).data('params-value')] = $(this).data('params');
          appendFilters($(this).data('params'),$(this).data('params-value'));

      });
      brand_filters = brand_filters.slice(0, -1);
      //  console.log(brand_filters);

      var category_filters = '';
      $('input:checkbox.category_check:checked').each(function () {
          category_filters += `${$(this).val()  },`;
          appendFilters($(this).data('params'),$(this).data('params-value'));

      });
      category_filters = category_filters.slice(0, -1);
      // console.log(category_filters);

      var checkbox_filter = '';
      var checkbox_filter_selected = '';

      html='';

      $('input:checkbox.checkbox_filter:checked').each(function () {
          checkbox_filter += `${$(this).val()  },`;

          appendFilters($(this).data('params'),$(this).data('params-value'));

      });

      checkbox_filter = checkbox_filter.slice(0, -1);

      var range_start = $('.js-input-from').val();

      var range_end = $('.js-input-to').val();




      if(adding!=DONTADD) {
          appendFilters('js-input-to', `${range_start  }-${  range_end  } грн`, TYPE_RANGE);
      }
      var handles = '';
      $('.handle_filter').each(function (index, value) {
          handles += `${$(this).data('handles')  }_${  $(this).val()  },`;

          //    console.log('handles='+handles);
      });
      handles = handles.slice(0, -1);
     // console.log('handles=' + handles);

      var in_stock = '';
      $('input:checkbox.in_stock:checked').each(function () {
          in_stock = $(this).val();
          appendFilters($(this).data('params'),$(this).data('params-value'));

      });
      // console.log(in_stock);

      var action = '';
      $('input:checkbox.action:checked').each(function () {
          action = $(this).val();
          appendFilters($(this).data('params'),$(this).data('params-value'));

      });

      var url = window.location.pathname;
      var last = url.substring(url.lastIndexOf('/') + 1);

      if (last != 'catalog') {
          cat_cpu = last;
      } else {
          cat_cpu = '';
      }

      let view = $('.js-catalog-toggle.active');
      if(view.hasClass('grid3')){
          view = 'grid3';
      }else if(view.hasClass('grid2')){
          view = 'grid2';
      }else{
          view = 'grid';
      }

      let q = $('#search').val();

      let sort = $('.sort-active').data('type');

      if(q) {
          appendFilters('find__filter', q, TYPE_SEARCH);
      }

      let ajaxData = {
          brand_filters,
          category_filters,
          range_start,
          range_end,
          in_stock,
          page,
          q,
          cheap: sort,
          action,
          cat_cpu,
          view,
          checkbox_filter,
          handles
      };

      return ajaxData;
  }

 let serialize = function(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(`${encodeURIComponent(p)  }=${  encodeURIComponent(obj[p])}`);
        }
    return str.join("&");
}


  $.each($('.js-single-range'), function (indexInArray, valueOfElement) { 

    let instance;
    let minval;
    let maxval;
  
    
    const $that = $(this);
    
    const $range = $that.find('.js-range-slider');
    const $inputFrom = $(valueOfElement).find('.js-input-val');


    let inStart = $inputFrom.data('range-val');

    
    inStart = parseInt(inStart); 
  
    let inFrom = $range.attr('data-range-min');
    let inTo = $range.attr('data-range-max'); 
    inFrom = parseInt(inFrom); 
    inTo = parseInt(inTo);
  
    
    $range.ionRangeSlider({
      skin: "round",
      min: inFrom,
      max: inTo,
      from: inStart,
      onStart: updateInputs,
      onChange: updateInputs,
      onFinish: updateInputs
    });
    instance = $range.data("ionRangeSlider");
  
    function updateInputs (data) {
      const {from} = data;
  
      $inputFrom.prop("value", from);
    }
    
  
    $inputFrom.on("change", function () {
        let val = $(this).prop("value");
  
        // validate
        if (val < inFrom) {
            val = inFrom;
        } else if (val > inTo) {
            val = inTo;
        }
  
        instance.update({
            from: val
        });
  
        $(this).prop("value", val);
  
    });
  
     
  });

  $.each($('.js-double-range'), function (indexInArray, valueOfElement) { 

    let instance;
    let minval;
    let maxval;
  
    
    const $that = $(this);
    
    const $range = $that.find('.js-range-slider');
    const $inputFrom = $(valueOfElement).find('.js-input-from');
    const $inputTo= $that.find('.js-input-to');


    let inStart = $inputFrom.data('range-start');
    let inEnd = $inputTo.attr('data-range-end');

    
    inStart = parseInt(inStart); 
    inEnd = parseInt(inEnd);
    
  
    let inFrom = $range.attr('data-range-min');
    let inTo = $range.attr('data-range-max'); 
    inFrom = parseInt(inFrom); 
    inTo = parseInt(inTo);

    
    $range.ionRangeSlider({
      skin: "round",
      type: "double",
      min: inStart,
      max: inEnd,
      from: inFrom,
      to: inTo,
      onStart: updateInputs,
      onChange: updateInputs,
      onFinish: updateInputsSend
    });
    instance = $range.data("ionRangeSlider");
  
    function updateInputs (data) {
      const {from} = data;
      const {to} = data;
  
      $inputFrom.prop("value", from);
      $inputTo.prop("value", to);

    }

    function updateInputsSend(data) {
      const {from} = data;
      const {to} = data;
  
      $inputFrom.prop("value", from);
      $inputTo.prop("value", to);

      // localStorage.setItem("priceFrom", from);
      // localStorage.setItem("priceTo", to);

      changeFilters();
      console.log(1212)
    }
    
  
    $inputFrom.on("change", function () {
        let val = $(this).prop("value");
  
        // validate
        if (val < inStart) {
            val = inStart;
        } else if (val > inTo) {
            val = inTo;
        }
  
        instance.update({
            from: val
        });
  
        $(this).prop("value", val);
  
    });
  
    $inputTo.on("change", function () {
        let val = $(this).prop("value");
  
        // validate
        if (val < inFrom) {
            val = inFrom;
        } else if (val > inEnd) {
            val = inEnd;
        }
  
        instance.update({
            to: val
        });
  
        $(this).prop("value", val);

        console.log(val);
    });
     
  });
  
});
  
  

