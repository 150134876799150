/* eslint-disable no-use-before-define */
/* eslint-disable no-new */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
// import UIkit from 'uikit';


// UIkit.drop('[data-drop]', {
//   // target: '[data-todrop]',
//   mode: 'hover',
//   animation: 'uk-animation-fade',
//   pos: 'bottom-left',
//   boundary: '.main-nav',
//   offset: 0
// });










// $( '[data-category-show]' ).hover(
//   (e) => {
    
//     const valCat = $(e.currentTarget).attr('data-category-show');
//     console.log(valCat);
//     // $( '.category-next.categoies-drop' ).removeClass('show');
//     $(`[data-category=${valCat}]`).addClass('show');

//   }, (e) => {
//     const valCat1 = $(e.currentTarget).attr('data-category-show');
//     console.log(valCat1);
//     // $('.category-next').removeClass('show');
    
//     setTimeout(() => {
//       if (showCat === 0) {
//         $(`[data-category=${valCat1}]`).removeClass('show');
//       }
//     }, 800);
//   }
// ); 




import MobileDetect from 'mobile-detect';
// media query event handler
if (matchMedia) {
  const mq = window.matchMedia("(min-width: 1024px)");
  mq.addListener(WidthChange);
  WidthChange(mq);
}

// media query change
function WidthChange(mq) {
  if (mq.matches) {
    
      $('[data-category-show]').addClass('hover-link');
      $('[data-category-show]').removeClass('click-drop');

     
      


      
  } else {
    $('[data-category-show]').removeClass('hover-link');
    $('[data-category-show]').addClass('click-drop');

    $(document).on("click",".level-1 [data-category-show].click-drop",function(e) {
      e.preventDefault();
      $(`.category-next.categoies-drop.level-3`).removeClass('show');
      const valCat = $(e.currentTarget).attr('data-category-show');
      
      $( '.category-next.categoies-drop.level-2' ).removeClass('show');
      $( '.category-next.categoies-drop.level-2' ).removeClass('show');
    
      $(`[data-category=${valCat}]`).addClass('show');
    });
    
    $(document).on("click",".level-2 [data-category-show].click-drop",function(e) {
      e.preventDefault();
      const valCat = $(e.currentTarget).attr('data-category-show');
      
      $( '.category-next.categoies-drop.level-3' ).removeClass('show');
      $(`[data-category=${valCat}]`).addClass('show');
    });
    
  }

}
window.onresize = function(event) {
if (matchMedia) {
  const mq = window.matchMedia("(min-width: 1024px)");
  mq.addListener(WidthChange);
  WidthChange(mq);
}
};

// click on mob cat main


const detector = new MobileDetect(window.navigator.userAgent);
const isMobile = detector.mobile();

$(document).on("click","nav.main-nav .nav-list__link",function(e) {
  
  // console.log(isMobile)
  if (isMobile === null) {
    // console.log(1)
  } else {
    e.preventDefault();
    // console.log(2)
  }
});

// show cat

let showCat;

$( '.categoies-drop.category-next' ).hover(
  (e) => {
    showCat = 1;
    // $( '.category-next.categoies-drop.show' ).removeClass('show');
    $(this).addClass('show');
  }, (e) => {
    showCat = 0;
    if ( showCat === 0 ) {
      // $( '.categoies-drop.category-next' ).removeClass('show');
    }
    
    
  }
); 


$( '.level-1 [data-category-show].hover-link' ).hover(
  (e) => {
    
    const valCat = $(e.currentTarget).attr('data-category-show');
    $( '.category-next.categoies-drop.level-2' ).removeClass('show');
    $( '.category-next.categoies-drop.level-3' ).removeClass('show');
    $(`[data-category=${valCat}]`).addClass('show');

    showCat = 1;
  }, (e) => {
    const valCat1 = $(e.currentTarget).attr('data-category-show');

    setTimeout(() => {
      if (showCat === 0) {
        $(`.category-next.categoies-drop.level-2[data-category=${valCat1}]`).removeClass('show');
       }
    }, 800);
  }
); 




if (isMobile === null) {
    
  console.log('ggggg')
 
  $( '.level-2 [data-category-show].hover-link' ).hover(
    (e) => {
  
      const valCat = $(e.currentTarget).attr('data-category-show');
      $( '.category-next.categoies-drop.level-3' ).removeClass('show');
      
    
      $(`[data-category=${valCat}]`).addClass('show');
  
      showCat = 1;
    }, (e) => {
      const valCat1 = $(e.currentTarget).attr('data-category-show');
      
      setTimeout(() => {
         if (showCat === 0) {
          $(`.category-next.categoies-drop.level-3[data-category=${valCat1}]`).removeClass('show');
         }
      }, 1200);
    }
  ); 

} else {
  console.log('ddddd')

  $(document).on("click",".level-2 [data-category-show]",function(e) {
    e.preventDefault()

    const valCat = $(e.currentTarget).attr('data-category-show');
    $( '.category-next.categoies-drop.level-3' ).removeClass('show');
    if (    $(`[data-category=${valCat}]`).length ) {

      $(`[data-category=${valCat}]`).addClass('show');
      

    } else {
            const link = $(this).attr ( 'href' );
            console.log(link)
          document.location = link;
    }
 
  });

}
 



  

     



$(document).on("click",".categoies-drop__mobile-link-back",function(e) {
  e.preventDefault();
  const valCat = $(e.currentTarget).attr('data-back-link');
  $(`.category-next.categoies-drop`).removeClass('show');
  $(`[data-category=${valCat}]`).addClass('show');
});