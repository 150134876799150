/* eslint-disable no-unused-vars */
/* eslint-disable func-names */

function sizePaginationItem(elem) {

  const item = elem;
  const itemWidth = item.width();
  item.css({'height': `${itemWidth  }px`});
  item.css({'width': `${itemWidth  }px`});
  
}



$(window).on("load resize scroll",function(e){
  sizePaginationItem( $('.product__pagination-link') );

  sizePaginationItem( $('.paginate_button') );
});


$(document).on("click",".paginate_button",function() {
    sizePaginationItem( $('.paginate_button') );
});

sizePaginationItem( $('.dataTables_wrapper .paginate_button') );

$(document).on("click",".table-sortable",function() {
  sizePaginationItem( $('.paginate_button') );
});

 