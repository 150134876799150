// import MobileDetect from 'mobile-detect';

// const detector = new MobileDetect(window.navigator.userAgent);
// export const isMobile = detector.mobile();

export const CLASSES = {
  active: 'is-active',
  hover: 'is-hover',
  sticky: 'is-sticky',
  open: 'is-open',
  navOpen: 'is-nav-open',
  shown: 'is-shown',
  mobile: 'is-mobile',
  noScroll: 'no-scroll'
};

export const DOM = {
  win: window,
  $html: $('html'),
  doc: document,
  $body: $('body')
};

export const ANIMATIONS = {
	start: 'start-animation',
  end: 'end-animation',
  full: 'full-animation',
};