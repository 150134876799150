/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable one-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable func-names */
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

import svg4everybody from 'svg4everybody';

// import bannerSlider from './modules/_slider-banner';
// import newsSlider from './modules/_slider-news-single';
// import partnerSlider from './modules/_slider-partners';
// import addproductsSlider from './modules/_slider-add-products';

import './modules/_range-slider';


import './modules/_nav-hover';
import './modules/_custom-scroll';
import './modules/_show-menu-cat';
import './modules/_show-search';
import './modules/_dd';
import  './modules/_white-bg';
import  './modules/_menu-modal';
import  './modules/_modal-filter';

import  './modules/_lightTabs';
import  './modules/_filter';
import  './modules/_tooltips';
import  './modules/_like';

import './modules/_pagination-width';
import './modules/_grid-catalog';
// import './modules/_slider-product';
import './modules/_show-user-info-mob';
import {DOM} from './_const';


import '../../node_modules/datatables/media/js/jquery.dataTables';



// loads the Icon plugin
UIkit.use(Icons);
svg4everybody();

const onContentLoaded = () => {
  let lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));

  const lazyLoad = () => {
    lazyImages.forEach((lazyImage) => {
      const topBreakpoint = lazyImage.classList.contains('lazy_last') ?
                            window.innerHeight + 500 : window.innerHeight + 200;

      if ((lazyImage.getBoundingClientRect().top <= topBreakpoint
          && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== 'none') {
        // eslint-disable-next-line
        lazyImage.src = lazyImage.dataset.src;
        // eslint-disable-next-line
        lazyImage.srcset = lazyImage.dataset.srcset;
        lazyImage.classList.remove('lazy');

        lazyImages = lazyImages.filter((image) => {
        return image !== lazyImage;
        });

        if (lazyImages.length === 0) {
          DOM.document.removeEventListener('scroll', lazyLoad);
          DOM.window.removeEventListener('resize', lazyLoad);
          DOM.window.removeEventListener('orientationchange', lazyLoad);
        }
      }
    });
  };

  DOM.doc.addEventListener('scroll', lazyLoad);
  DOM.win.addEventListener('resize', lazyLoad);
  DOM.win.addEventListener('orientationchange', lazyLoad);
}

// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.');


const onLoad = () => {
  // DOM.$body.removeClass('loader');
  // if ( $('.js-shops-slider-buy').length ) {
  //   bannerSlider.init();
  // }
  // if ( $('.js-news-slider').length ) {
  //   newsSlider.init();
  // }
  // if ( $('.js-partners-item').length ) {
  //   partnerSlider.init();
  // }
  // if ( $('.js-slider-add-products').length ) {
  //   addproductsSlider.init();
  // }



  $(document).ready(function ($) {



      if ( $('nav.main-nav') ) {
        $('<div class="bg-modal-in"></div>').appendTo('.header-bot > .uk-container');
      }

    
      // eslint-disable-next-line func-names
      $(document).ready(function() {

        // table sort

        $.each($('.table-sortable'), function (indexInArray, valueOfElement) { 
          let elem = $(this).addClass(`sortable-table${indexInArray}`);
          elem.DataTable();
        }).on( 'draw', function () {
          sizePaginationItem( $('.paginate_button') );
        } );

        

        // document page fix

        // function getHeight(element) {
        //   let height = element.height();
        //   return height
        // }

        // function setHeight() {
        //   let $items = $('.document-detail-tabs .l-section');

        //   let rsideH = getHeight($('.document-detail-tabs .r-section'));


        //   $items.css({'height': `${rsideH  }px`});



        // }
        // setHeight();
        // $( window ).resize(function() {
        //   setHeight();
        // });
       
        // корзина способы доставки
        $("input[name$='delivery-type']").click(function() {
            const value = $(this).val();

            const parents = $(this).parents('.form-inf');
            parents.find('.form-def__radio').removeClass('active');
            $(this).addClass('active');

            
            $('.form-inf__varible').hide();
            $(`#address-delivery-${value}`).show();

            // if ($('.free .form-def__radio').hasClass('active')){
            //   $('.comment-option .alternative').addClass('hide');
            //   $('.form-inf .np-wrap').addClass('hide');
            // } else{
            //   $('.comment-option .alternative').removeClass('hide');
            //   $('.form-inf .np-wrap').removeClass('hide');
            // }
        });

        $.each( $("input[name$='delivery-type']:checked") , function (indexInArray, valueOfElement) { 
           
          const value = $(this).val();

          const parents = $(this).parents('.form-inf');
          parents.find('.form-def__radio').removeClass('active');
          $(this).addClass('active');

          
          $('.form-inf__varible').hide();
          $(`#address-delivery-${value}`).show();

        });

        $(document).on("click","[data-quantity='plus']",function() {
            let $parrent = $(this).closest('.input-group');
            let $input = $parrent.find('.input-group-field');

            // eslint-disable-next-line no-undef
            const currentVal = parseInt($input.val());
            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(currentVal)) {             
                // eslint-disable-next-line no-undef
                $input.val(currentVal + 1);
            } else {
              $input.val(0);
            }
        });

        $(document).on("click","[data-quantity='minus']",function() {
          let $parrent = $(this).closest('.input-group');
          let $input = $parrent.find('.input-group-field');

          // eslint-disable-next-line no-undef
          const currentVal = parseInt($input.val());
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(currentVal) && currentVal > 0) {             
              // eslint-disable-next-line no-undef
              $input.val(currentVal - 1);
          } else {
            $input.val(0);
          }
      });

    
      $(document).mouseup(function (e){ 

        if($(".uk-offcanvas-container #aside-mob-cart").is(":visible")){
          var $div = $("#modal-sroller-wr-cart .product__item ");
          if (!$div.is(e.target)
              && $div.has(e.target).length === 0) {               
                UIkit.offcanvas('#aside-mob-cart').hide();
                console.log('click');
          } else {          
              console.log('no-visible');
          }
        } 
       
      }); 

     


        $( ".type-view .type-btn.cube" ).click(function(e) {
          e.preventDefault();     
          $(this).addClass('active');
          $(this).next().removeClass('active');
          $('.brands-list').addClass('cube').removeClass('line');
        });
      
        $( ".type-view .type-btn.line" ).click(function(e) {
          e.preventDefault();     
          $(this).addClass('active');
          $(this).siblings('.type-btn').removeClass('active');
          $('.brands-list').addClass('line').removeClass('cube');
        });

      
       

       // табы
        $(".tabs-variable__item .link").click(function() {
          const value = $(this).attr('data-attr');

          const parents = $(this).parents('.tabs-variable');
          parents.find('.tabs-variable__item .link').removeClass('active');
          $(this).addClass('active');

          $('.tabs-vertical-wrap .r-section .tabs-contant').hide();
          $(`.tabs-contant.${value}`).show();
      
        }); 

       
      
    

        // О компании аккардеон
        $('.active-vacancies   .vacancies__item .title-item').click(function(e) {
          e.stopPropagation();
          e.stopImmediatePropagation();
          let item = $(this).closest('.vacancies__item');
          let text = $(this).siblings('.title-text');
          let isActive = item.hasClass('active');
          


          if ( isActive ) {
            item.removeClass('active');
            text.slideUp();
          } else {
            item.addClass('active');
            text.slideDown();
            item.siblings('.vacancies__item.active').removeClass('active')
              .find('.title-text').slideUp();
          }
          return false;
        });

        $('.tabs__item:first > .title-tabs').trigger('click');

        var inputs = document.querySelectorAll('.inputfile');
          Array.prototype.forEach.call(inputs, function(input){
            var label	 = input.nextElementSibling,
                labelVal = label.innerHTML;
            input.addEventListener('change', function(e){
              var fileName = '';
              if( this.files && this.files.length > 1 )
                fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
              else
                fileName = e.target.value.split( '\\' ).pop();
              if( fileName )
                label.querySelector( 'span' ).innerHTML = fileName;
              else
                label.innerHTML = labelVal;
            });
        });

      });
  });
 
}
$(DOM.doc).ready(onLoad);
DOM.doc.addEventListener('DOMContentLoaded', onContentLoaded);

