/* eslint-disable func-names */
$( ".js-show-search" ).on( "click", function() {
 $('.main-search').addClass('show');
 $('.bg-modal-in').addClass('in-show');
});

$(document).mouseup(function (e){ // событие клика по веб-документу
  const $div = $(".main-search"); // тут указываем ID элемента
  if (!$div.is(e.target) // если клик был не по нашему блоку
      && $div.has(e.target).length === 0) { // и не по его дочерним элементам
    $div.removeClass('show'); // скрываем его
    $('.bg-modal-in').removeClass('in-show');
    $(".drop-search").hide();
  }
});

$( ".js-show-search" ).on( "click", function() {
  $('.main-search').addClass('show');
  $('.bg-modal-in').addClass('in-show');
 });

 $('.main-search__input').keydown(function(e) {
  if(e.keyCode === 13) {
    $(".drop-search").show();
  }
});

$(".main-search__input").focusin(function() {
  $(".drop-search").show();
}).focusout(function () {
  // $(".drop-search").hide();
});