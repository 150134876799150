import UIkit from 'uikit';


UIkit.dropdown('[data-drop]', {
  // target: '[data-todrop]',
  mode: 'hover',
  animation: 'uk-animation-fade',
  pos: 'bottom-left',
  boundary: '.main-nav',
  offset: 0
});


// UIkit.dropdown('.next-level', {
//   // target: '[data-todrop]',
//   mode: 'hover',
//   animation: 'uk-animation-fade',
//   pos: 'bottom-right',
//   boundary: '.categoies-drop',
//   offset: 0
// });



UIkit.util.on('[data-drop]', 'beforeshow', function eShow() {

  $('.bg-modal-in').addClass('in-show');
  $('.main-nav').addClass('in-show');

}); 


UIkit.util.on('[data-drop]', 'hide', function eShow() {

  $('.bg-modal-in').removeClass('in-show');
  $('.main-nav').removeClass('in-show');
}); 