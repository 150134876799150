/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
import UIkit from 'uikit';
import '../../../node_modules/jquery.nicescroll'


UIkit.toggle('.js-toggle-filter', {
  target: '+',
  animation: 'uk-animation-fade'
});



$(document).on("click",".js-toggle-filter",function() {
  $(this).find('.filter__toggle-arrow').toggleClass('active');
  $(this).closest('.filter__sub-item').toggleClass('active'); 
 

 

});

$.each($('.js-toggle-body'), function (indexInArray, valueOfElement) { 
   if ( !$(this).is('[hidden]') ) {
     $(this).closest('.filter__sub-item').find('.filter__toggle-arrow').addClass('active');
   }
   if ( $(this).is('[hidden]') ) {
    $(this).closest('.filter__sub-item').addClass('active'); 
  }
});