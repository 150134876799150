/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */

import UIkit from 'uikit';
// import Swiper from 'swiper';
// import SimpleBar from 'simplebar';
// import dragscroll from 'dragscroll';
import '../../../node_modules/jquery.nicescroll';


if ( $("#modal-sroller-wr").length ) {


  $(document).ready(function () {

   
  

    $("#modal-sroller-wr").niceScroll({
        autohidemode: false,
        cursorcolor:"rgb(185, 207, 238)",
        cursorborder: "none", 
        cursorwidth:"4px",
        bouncescroll: true,
        cursorborderradius: 0
      });

    // barFilter.recalculate();
      
    $.each($('.sroller-el-offcanvas'), function (indexInArray, valueOfElement) { 
      $(this).niceScroll({
        autohidemode: false,
        cursorborder: "none", 
        cursorcolor:"rgb(185, 207, 238)",
        cursorwidth:"4px",
        bouncescroll: true,
        cursorborderradius: 0
      });
      
      
      console.log($("this").getNiceScroll());
    });

    UIkit.util.on('.js-toggle-filter', 'shown', function () {

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));

        $("#modal-sroller-wr").getNiceScroll().resize();

        $.each($('.sroller-el-offcanvas'), function (indexInArray, valueOfElement) { 

          $(this).getNiceScroll($(this)).resize();
          
          
          console.log($("this").getNiceScroll());
        });
      }, 1280);


      
    });

  });




  // const barFilter = new SimpleBar(document.getElementById('modal-sroller-wr'), {
  //   autoHide: false
  // });

  //  // scrollde block
  //  for (const scrollArea1 of document.getElementsByClassName('sroller-el-offcanvas')) {
  //   new SimpleBar(scrollArea1, {
  //       autoHide: false
  //   });
  // }

  $(document).on("click",".js-close-off-filter",function(e) {
    e.preventDefault();
    UIkit.offcanvas('#aside-mob-filter').hide();
  });
  
  $(document).on("click",".js-show-filter",function(e) {
    e.preventDefault();
    // UIkit.modal($('#modal-mob-filter')).show();
     UIkit.offcanvas('#aside-mob-filter').show();
   // $('#aside-mob-filter').addClass('uk-open');
    $('body, html').addClass('stop-s');


    
 
  
    
  });
  UIkit.util.on('#aside-mob-filter', 'shown', function () {

    $.each($('.sroller-el-offcanvas'), function (indexInArray, valueOfElement) { 
      $(this).getNiceScroll().resize();
    });
    $("#modal-sroller-wr").getNiceScroll().resize();

    // $('#aside-mob-filter .simplebar-content-wrapper').addClass('dragscroll');

      // dragscroll.reset();

      
      // function removePointerListeners(target) {
      //   ['mousedown', 'click', 'dblclick', 'touchstart', 'touchend', 'touchmove'].forEach((e) => {
      //     target.el.removeEventListener(e, target.onPointerEvent);
      //     target.el.addEventListener(e, target.onPointerEvent, true);
      //   });
      // }
    
      // // remove pointer event listeners
      // removePointerListeners(barFilter);
  
  });
  UIkit.util.on('#aside-mob-filter', 'hide', function () {
   $('body, html').removeClass('stop-s');
  
  });

}


if ( $("#aside-mob-cart").length ) {

  if ( $('#modal-sroller-wr-cart').length ) {
    // const barFilter1 = new SimpleBar(document.getElementById('modal-sroller-wr-cart'), {
    //   autoHide: false
    // });

    // $('#modal-sroller-wr-cart .simplebar-content-wrapper').addClass('dragscroll');

   
  }
  
  $(document).on("click",".js-close-cart",function() {
    UIkit.offcanvas('#aside-mob-cart').hide();
  });

  
  
  
  $(document).on("click",".js-show-cart",function(e) {
    e.preventDefault();
    // UIkit.modal($('#modal-mob-filter')).show();

    
    
    UIkit.offcanvas('#aside-mob-cart').show();
    
     

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 20);
    
  });

  let posTop 
  UIkit.util.on('#aside-mob-cart', 'beforeshow', function () {

    posTop = $(window).scrollTop();
    

    $('body, html').addClass('stop-s');

    $("#modal-sroller-wr-cart").niceScroll({
      autohidemode: false,
      cursorcolor:"rgb(185, 207, 238)",
      cursorborder: "none", 
      cursorwidth:"4px",
      bouncescroll: true,
      cursorborderradius: 0
    });

    console.log(`${'scroll added - '}${  posTop}`);
    
    window.scrollTo(0, posTop);




  
  });
  UIkit.util.on('#aside-mob-cart', 'hide', function () {
    $('body, html').removeClass('stop-s');
    window.scrollTo(0, posTop);
  });


  // function mapTouchEvents(event,simulatedType) {
	
  //   //Ignore any mapping if more than 1 fingers touching  
  //   if(event.changedTouches.length>1){
  //     return;
  //   }
    
  //   const touch = event.changedTouches[0];
  //   console.log(event.changedTouches);
    
  //   //--https://developer.mozilla.org/en/DOM/document.createEvent--
  //   const eventToSimulate = document.createEvent('MouseEvent');
    
  //   //--https://developer.mozilla.org/en-US/docs/Web/API/event.initMouseEvent--
  //   eventToSimulate.initMouseEvent(
  //     simulatedType,		//type
  //     true,				//bubbles
  //     true,				//cancelable
  //     window,				//view
  //     1,					//detail
  //     touch.screenX,		//screenX
  //     touch.screenY,		//screenY
  //     touch.clientX,		//clientX
  //     touch.clientY,		//clientY
  //     false,				//ctrlKey
  //     false,				//altKey
  //     false,				//shiftKey
  //     false,				//metaKey
  //     0,					//button
  //     null				//relatedTarget
  //   );
  
  //   touch.target.dispatchEvent(eventToSimulate);
  //   //This ignores the default scroll behavior
  //   event.preventDefault();				
    
  // }

 // document.addEventListener('touchstart',function(e){ mapTouchEvents(e,'mousedown'); },true);
  // document.addEventListener('touchmove',function(e){ mapTouchEvents(e,'mousemove'); },true);
  // document.addEventListener('touchend',function(e){ mapTouchEvents(e,'mousedown'); },true);
  // document.addEventListener('touchcancel',function(e){ mapTouchEvents(e,'mouseup'); },true);
}



// $(document).ready(function () {




  
   

  
  
 
  
//     // function removePointerListeners(target) {
//     //   ['mousedown', 'click', 'dblclick', 'touchstart', 'touchend', 'touchmove'].forEach((e) => {
//     //     target.el.removeEventListener(e, target.onPointerEvent);
//     //   });
//     // }
  
//     // // remove pointer event listeners
//     // removePointerListeners(simplebar11);
  
  
// });



// const swiperScroll = new Swiper('.swiper-container.js-slider-filter', {
//   direction: 'vertical',
//   slidesPerView: 'auto',
//   freeMode: true,
//   scrollbar: {
//     el: '.js-slider-filter-scroll',
//     draggable: true,
    
//   },
//   mousewheel: true,
// });


// $('#modal-mob-filter .filter-body.sroller-el').hover(function () {
//     swiperScroll.mousewheel.disable();

//     console.log(213123);
      
//   }, function () {
//     swiperScroll.mousewheel.enable();
//   }
// );

// $('#modal-mob-filter .filter-body.sroller-el').on("swipe",function(){
//   swiperScroll.mousewheel.disable();
// });
// $('#modal-mob-filter .filter-body.sroller-el').on("tap",function(){
//   swiperScroll.mousewheel.disable();
// });
// $('#modal-mob-filter .filter-body.sroller-el').on("taphold",function(){
//   swiperScroll.mousewheel.disable();
// });

// UIkit.util.on('#modal-mob-filter', 'shown', function () {
//   // barFilter.recalculate();
//   // console.log(123123);
//   // setTimeout(() => {
//   //   barFilter.recalculate();
//   // }, 300);
// });
// $(document).on("click",".js-show-mob-menu .js-burger-close, .nav-list .nav-list__link",function() {
//   UIkit.modal($('#modal-mob-menu')).hide();
// });