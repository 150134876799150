/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import select2 from 'select2';

$.each($('.form-def__select'), function (indexInArray, valueOfElement) { 
    const placeholderval = $(this).data('placeholder') || '';
  $(this).select2({
      // dropdownCssClass: 'sort-list',
      "language": {
          "noResults": function() {
              return "Ничего не найдено"
          }
      },
      placeholder: placeholderval,
      // maximumSelectionLength: 1,
      minimumResultsForSearch: -1,
  });
});


$.each($('.form-def__select-search'), function (indexInArray, valueOfElement) { 
    const placeholderval = $(this).data('placeholder') || '';
  $(this).select2({
      // dropdownCssClass: 'sort-list',
      "language": {
          "noResults": function() {
              return "Ничего не найдено"
          }
      },
      placeholder: placeholderval
  });
});

// eslint-disable-next-line prefer-const
let valload = $('.cabinet-outlets__item #delivery-type .form-def__select').val();

if ( valload === '1' ) {
    $('.cabinet-outlets__item #delivery-type').next().hide();
}

$('.cabinet-outlets__item .form-def__select').on('select2:select', function (e) {
    // eslint-disable-next-line prefer-const
    let tval = $(this).val();
    console.log(tval);

    if ( tval === '1' ) {
        $('.cabinet-outlets__item #delivery-type').next().hide();
    } else {
        $('.cabinet-outlets__item #delivery-type').next().show();
    }
});



$('#business-input').on('select2:select', function (e) {
    // eslint-disable-next-line prefer-const
    let tval = $(this).val();
    console.log(tval);
    const $elem = $('#website-input');

    if ( tval === '2' ) {
        $elem.prop('disabled', false).prop('required', true);
    } else {
        $elem.prop('disabled', true).prop('required', false);
        $elem.val('');
    }
});

